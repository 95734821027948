body{
    font-family: 'Visby CF' !important;
}
.containerWrapper{
    background: linear-gradient(
135deg
,transparent 5.68px,#fff 5.69px) 0 0,linear-gradient(
45deg
,#fff 2.8px,transparent 2.81px) 0 0,linear-gradient(
135deg
,#fff 2.8px,transparent 2.81px) 0 100%,linear-gradient(
45deg
,transparent 5.68px,#fff 5.69px) 0 100%;
    background-repeat: repeat-x;
    background-size: 8px 4px;
    padding: 4px 0;
    max-width: 500px;
    margin: 50px auto;
}
.containerrecipt{
  
    background: white;
    padding: 0px 30px 30px 30px;
}
.logoContainer{
    text-align: center;
}
.logoImg{
    margin-top: 20px;
}
.OrgName{
    text-align: center;
}
.invoiceNo{
    text-align: center;
}
.language-select{
    position: absolute;
    right: 10px;
}
.containerWrapper .MuiGrid-item {
    margin: 0;
    padding: 5px 10px;
    box-sizing: border-box;
    font-weight: 600;
}
.containerWrapper .MuiGrid-container{
    margin-top: 15px;
    border: 1px solid #e4e5e6;
}
.text-center{
    text-align: center;
}
.bottomtxt{
    margin-top: 15px;
}
.text-right{
 text-align: right;
}