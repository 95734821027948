code {
  display: inline-block;
  padding: 0.25rem 0.5rem;
}
.pin-field-container {
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  justify-content: center;
  margin: 4rem 0;
}
.pin-field {
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 0.3rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 3.5rem;
  outline: none;
  text-align: center;
  transition-duration: 250ms;
  transition-property: background, color, border, box-shadow, transform;
  width: 20%;
}
.pin-field:focus {
  border-color: #007bff;
  outline: none;
  transform: scale(1.05);
}
.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: #dc3545;
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.pin-field:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
swd-pin-field[completed] .pin-field {
  border-color: #28a745;
  background-color: rgba(40, 167, 69, 0.1);
}
@keyframes shake {
  from {
    transform: scale(1.05) translateY(-5%);
 }
  to {
    transform: scale(1.05) translateY(5%);
 }
}
.makeStyles-loginwrapper-1 .MuiFormControl-root{
  width: 100% !important;
}